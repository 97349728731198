import React from "react";
import { Link } from "gatsby";
import { useFetchStakingPoolStatsLatestQuery } from "../graphql/thegraph/generated";
import { CTSI_POS_THEGRAPH_ENDPOINT, CTSI_POS_POOL_ADDRESS } from "../config";

export const Main = () => {
  const { data: theGraphQueryResult } = useFetchStakingPoolStatsLatestQuery(
    { endpoint: CTSI_POS_THEGRAPH_ENDPOINT },
    { pool: CTSI_POS_POOL_ADDRESS }
  );
  return (
    <div>
      <h2>Cartesi staking made to last.</h2>
      <p>
        Durable takes care of the hard work. We make sure your staked CTSI can
        provide a steady income.
      </p>
      <p>
        Our private node has been running without interruption since the reserve
        mining kick off. We brought custom optimizations to the{" "}
        <a
          href="https://github.com/cartesi/noether"
          target="_blank"
          rel="noreferrer"
        >
          Noether
        </a>{" "}
        source code in order to guarantee block validation whenever possible.
        Without those improvements transactions can sometimes fail.
      </p>
      <p>
        With the release of CTSI staking delegation we can proudly share that
        acquired experience with the community. We don&apos;t miss any block.
        You won&apos;t miss any reward.
      </p>
      <p>
        Our current fee is of{" "}
        {theGraphQueryResult?.stakingPool?.fee.commission &&
          theGraphQueryResult.stakingPool.fee.commission / 100}
        <small>%</small>. This is adjusted regularly in order to stay break even
        and cover our operating costs. Our fee is fair and will stay fair:{" "}
        <Link to="/transparency-report">check our transparency report</Link>.
      </p>
      <p>
        Choose our node on{" "}
        <a
          href="https://explorer.cartesi.io/pools/0x8f57ae7ddde63412509e71997b2438a3e6dcb763"
          target="_blank"
          rel="noreferrer"
        >
          explorer.cartesi.io
        </a>
        . Pool address:
      </p>
      <p>
        <a
          className="address"
          href="https://explorer.cartesi.io/pools/0x8f57ae7ddde63412509e71997b2438a3e6dcb763"
          target="_blank"
          rel="noreferrer"
        >
          0x8f57ae7ddde63412509e71997b2438a3e6dcb763
        </a>
      </p>
    </div>
  );
};
