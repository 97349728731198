import React from "react";
import { Main } from "../components/Main";
import { SEO } from "../components/SEO";

const IndexPage = () => {
  return <Main />;
};

export default IndexPage;

export function Head() {
  return <SEO />;
}
